const { dropAllShows } = require("../utils/showUtils")

const DropShows = () => {
    const youSure = () => {
        if(window.confirm("are you sure????") === true) {
            alert("asfd")
            dropAllShows()
        }
    }
    return (<div className={`border-2 m-5 p-5 border-dashed "border-red-400"`}>
    <div>
        <button className="bg-red-400 text-white hover:bg-red-700 rounded p-2 font-mono" onClick={youSure}>drop all shows</button>
    </div>
    </div>)
}
export default DropShows